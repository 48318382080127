@import '../../../../scss/theme-bootstrap';

.product-brief {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
  @include breakpoint($medium-up) {
    margin-bottom: 20px;
  }
  &.IE-min-height-processed {
    min-height: 800px;
  }
  &__image-container {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    &-top {
      display: table;
      @if $cr24_plp == true {
        position: absolute;
        z-index: 100;
        padding: 12px;
        pointer-events: none;
      }
    }
    .product-brief__flag {
      .product-brief__flag--preorder,
      .product-brief__flag--text,
      .product-brief__flag--subheader {
        margin-#{$rdirection}: 10px;
        padding-#{$rdirection}: 12px;
        @if $cr24_plp == false {
        .product-brief[data-rating]:not([data-rating='0.0']) & {
          padding-#{$rdirection}: 10px;
          border-#{$rdirection}: 2px solid $color-gray-op10;
        }
        }
      }
      .product-brief__flag--preorder {
        display: none;
      }
    }
    .product-brief__image-badge {
      pointer-events: none;
      position: absolute;
      top: 0;
      #{$rdirection}: 0;
      z-index: 1;
    }
  }
  &__image-wrapper {
    margin: 0 auto;
    position: relative;
    @include breakpoint($medium-up) {
      &--has-alt-image:hover {
        .product-brief__image {
          opacity: 0;
          visibility: hidden;
        }
        .product-brief__image--alt {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  &__image {
    width: 100%;
    height: auto;
    min-height: 330px;
    margin: 0 auto;
    opacity: 1;
    visibility: visible;
    text-align: center;
    transition: opacity 1s ease-in-out;
    &.product-brief__image--alt {
      #{$ldirection}: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      visibility: hidden;
      z-index: 1;
    }
  }
  &__image-link {
    text-decoration: none;
    &.slick-slide {
      .product-brief__image--alt {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  &__shades-overlay,
  &__sized-overlay {
    display: none;
    position: fixed;
    bottom: 0;
    #{$ldirection}: 0;
    min-height: 450px;
    width: 100%;
    padding: 30px 0 210px;
    background-color: $color-white;
    z-index: 995;
    &.active {
      display: block;
    }
    &-wrapper {
      display: inline-block;
      width: 100%;
      margin-bottom: 20px;
    }
    &__content {
      position: relative;
    }
    &-button {
      @include dropdown-shades;
      cursor: pointer;
      display: flex;
      vertical-align: middle;
      .product-brief {
        &__shade-arrow,
        &__sized-arrow {
          height: 19px;
          width: 10px;
          background: url('#{$base-theme-path}svg-icons/src/icon--dropdown.svg') center no-repeat;
          flex-shrink: 0;
        }
      }
    }
    &-title {
      @include typography-body-text;
      margin: 0 20px;
      color: $color-core-black;
    }
    &__bottom {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 20px;
    }
    .product-brief {
      &__add-to-bag {
        display: block;
        width: 100%;
        .product__button {
          @include cta-primary;
          display: block;
          width: 100%;
          margin: 0;
          &.button--disabled {
            @include cta-primary-gray;
            cursor: not-allowed;
          }
        }
        .product__notify-me {
          display: block;
          width: 100%;
        }
      }
      &__price {
        @include typography-link-text;
        position: absolute;
        display: inline-block;
        top: 0;
        #{$rdirection}: 0;
        margin: 0 20px;
        color: $color-core-black;
        &.discount_price {
          .old_price {
            color: $color-gray-op65;
            text-decoration: line-through;
          }
        }
      }
    }
    &__close {
      @include typography-body-text;
      display: block;
      color: $color-core-black;
      text-align: center;
      margin: 20px;
      text-decoration: none;
    }
    .product__inventory-status {
      @include typography-annotation-text;
      li {
        padding-bottom: 20px;
      }
    }
    #colorbox & {
      .product__button {
        color: $color-white;
      }
    }
  }
  &__shades {
    width: 100%;
    min-height: auto;
    min-width: auto;
    margin: 10px 0;
    @include breakpoint($medium-up) {
      min-height: auto;
      min-width: auto;
      overflow: hidden;
      padding: 0 15px;
      margin: 0 auto;
    }
    &-overlay {
      &-button {
        .product-brief {
          &__shade-dot,
          &__first-shade-dot,
          &__second-shade-dot {
            border-radius: 50%;
            height: 19px;
            width: 19px;
            flex-shrink: 0;
          }
          &__shade-name {
            margin: 0 10px;
            white-space: nowrap;
            overflow: hidden;
            color: $color-core-black;
          }
        }
      }
      &-title {
        margin-bottom: 5px;
      }
      .product-brief-shades__shade {
        width: 64px;
        height: 64px;
        margin: 0 auto;
      }
    }
    .slick-slide & {
      min-height: auto;
    }
  }
  &__sized {
    &-buttons,
    &-overlay {
      .sku-list__list {
        .sku-list__item {
          @include breakpoint($medium-up) {
            padding-top: 20px;
          }
          &:only-child {
            @include breakpoint($medium-up) {
              display: none;
            }
            @include breakpoint($large-up) {
              display: inline-block;
              padding-top: 0;
            }
            .sku-list__button {
              cursor: auto;
            }
            .sku-list__button::before {
              display: none;
            }
            .sku-list__button.button--active {
              color: $color-gray-op65;
              &:hover {
                color: $color-gray-op65;
              }
            }
          }
          .sku-list__button {
            @include typography-label-text;
            @include swap_direction(margin, 0 20px 20px 0);
            height: auto;
            width: auto;
            background: transparent;
            border: none;
            color: $color-gray-op65;
          }
          .sku-list__button:hover {
            background-color: transparent;
            color: $color-core-black;
          }
          .sku-list__button::before {
            @include swap_direction(margin, 0 10px 1px 0);
            content: '';
            display: inline-block;
            width: 18px;
            height: 18px;
            vertical-align: middle;
            background: url('#{$base-theme-path}svg-icons/src/icon--radio.svg') center no-repeat;
          }
          .sku-list__button.button {
            padding: 0;
          }
          .sku-list__button.button--active {
            top: 0;
            color: $color-core-black;
          }
          .sku-list__button.button--active:hover {
            color: $color-core-black;
          }
          .sku-list__button.button--active::before {
            background: url('#{$base-theme-path}svg-icons/src/icon--radio-checked.svg') center
              no-repeat;
          }
        }
      }
    }
    &-buttons {
      .multi-sku & {
        margin-top: 20px;
      }
      .sku-list__list {
        .sku-list__item {
          margin: 0;
          padding: 0;
          &:last-of-type {
            .sku-list__button {
              margin-#{$rdirection}: 0;
            }
          }
          .button {
            display: flex;
          }
        }
      }
    }
    &-overlay {
      .sku-list {
        padding: 0 20px;
        &__list {
          .sku-list__item {
            padding: 30px 0;
            border-top: 1px solid $color-line;
            width: 100%;
            &:last-of-type {
              padding-bottom: 0;
            }
            .sku-list__button {
              position: relative;
              margin: 0;
              color: $color-core-black;
            }
            .sku-list__button::before {
              width: 32px;
              height: 32px;
            }
          }
        }
      }
      &-button {
        .product-brief__sized-name {
          @include typography-label-text;
          margin-#{$rdirection}: 10px;
          color: $color-core-black;
          &::before {
            @include swap_direction(margin, 0 10px 1px 0);
            content: '';
            display: inline-block;
            width: 18px;
            height: 18px;
            vertical-align: middle;
            background: url('#{$base-theme-path}svg-icons/src/icon--radio-checked.svg') center
              no-repeat;
          }
          &:only-child {
            color: $color-gray-op65;
            &::before {
              display: none;
            }
          }
        }
      }
      &-title {
        margin-bottom: 20px;
      }
    }
    &-price {
      position: absolute;
      top: 0;
      #{$rdirection}: 0;
      padding: 7px 0;
      color: $color-gray-on-light;
      font-weight: bold;
      .new_price {
        @include bbtext-bold;
        color: $color-core-red;
        font-weight: bold;
      }
      .old_price {
        @include bbtext-regular;
        color: $color-core-black;
        text-decoration: line-through;
      }
    }
  }
  &__size {
    @include typography-label-text;
    display: none;
    margin-bottom: 20px;
    color: $color-gray-op65;
    @include breakpoint($large-up) {
      @if $cr24_plp == false {
        display: block;
      }
    }
  }
  &__abstract {
    width: 100%;
    text-align: #{$ldirection};
    margin: 0 auto;
    &-item {
      display: inline-block;
      &:not(:last-of-type)::after {
        content: '•';
        display: inline-block;
        color: $color-gray-op65;
        margin: 0 8px;
      }
    }
    .mpp-container .product-brief & {
      margin-bottom: 20px;
      .product-brief__short-desc {
        @if $cr24_plp == true {
          margin-bottom: 12px;
        }
      }
      .product-brief__price {
        width: auto;
        margin: 0;
        .price {
          color: $color-core-black;
        }
        &.discount_price .price {
          color: $color-core-red;
        }
      }
    }
  }
  &__headline {
    display: inline-block;
    width: 100%;
    &-link {
      display: inline-block;
      color: $color-core-black;
      text-decoration: none;
    }
  }
  &__header {
    color: $color-core-black;
    text-transform: none;
    width: 100%;
  }
  &__short-desc {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    color: $color-gray-op65;
    &-link {
      white-space: nowrap;
    }
  }
  &__bottom {
    width: 100%;
    margin: 0;
  }
  &__bottom-mobile {
    .product-brief__short-desc-link {
      @include typography-link-text;
      width: 100%;
    }
    .product-brief__add-to-bag {
      width: 50%;
      display: inline-table;
      margin: 0;
      .product__button--add-to-bag {
        @include cta-primary;
        width: 50%;
        height: auto;
        @include breakpoint($medium-up) {
          width: auto;
          max-width: 238px;
        }
      }
      .button--disabled {
        @include cta-primary-gray;
        cursor: not-allowed;
      }
      .product__notify-me {
        @include breakpoint($medium-up) {
          display: block;
          margin: 0 auto;
          width: 50%;
        }
        > li {
          width: 100%;
          .button {
            @include cta-primary;
          }
        }
      }
    }
  }
  &__inventory_status {
    @include typography-link-text;
    display: block;
    width: 100%;
    height: auto;
    padding: 0;
    color: $color-gray-op65;
    text-align: #{$ldirection};
  }
  .product__inventory-status {
    .preorder-noshop {
      position: relative;
      bottom: 0;
      width: auto;
      @include breakpoint($medium-up) {
        float: none;
        margin: 0 auto;
      }
    }
  }
  .p-w-r .pr-category-snippet__total {
    display: none;
  }
  &__shades-count,
  &__sizes-count,
  &__abstract-size {
    @include typography-link-text;
    color: $color-gray-op65;
    text-align: #{$ldirection};
    margin-bottom: 20px;
    display: inline-block;
  }
  &__price {
    display: none;
    margin: 0;
    width: auto;
    .price-unit-price {
      color: $color-gray-op65;
    }
  }
  &__quickshop-buttons {
    .product-brief {
      &__add-to-bag {
        display: inline-block;
      }
      &__quick-shop-cta {
        display: none;
        @include breakpoint($large-up) {
          display: inline-block;
          & ~ .product-brief__add-to-bag {
            display: none;
          }
        }
      }
    }
  }
  &__product-info-wrapper {
    display: flex;
    flex-direction: column;
    flex: initial;
    width: 100%;
    height: 100%;
  }
  @if $cr24_plp == false {
    .product-brief__shades,
    .product-brief__sized-title,
    .product-brief__size,
    .product-brief__bottom {
      @include breakpoint($large-up) {
        display: none;
      }
    }
    &:hover,
    &.hover {
      .product-brief__abstract {
        @include breakpoint($large-up) {
          display: none;
        }
      }
      .product-brief__shades,
      .product-brief__sized-title,
      .product-brief__size,
      .product-brief__bottom {
        @include breakpoint($large-up) {
          display: block;
        }
      }
    }
  }
  .product-full__review-snippet {
    .pr-rating-stars {
      .pr-star-v4 {
        display: flex;
        &:first-of-type {
          margin-bottom: 3px;
        }
      }
    }
    .p-w-r {
      .pr-snippet {
        .mpp-container & {
          .pr-snippet-rating-decimal,
          .pr-category-snippet__total {
            @if $cr24_plp == true {
              color: $color-gray-on-light;
            }
          }
        }
        padding: 0;
      }
    }
  }
  .product-brief__headline-review-link {
    position: relative;
    display: none;
    top: -1px;
    #{$ldirection}: 0;
    margin: 0;
    line-height: normal;
  }
  &__rating {
    .mpp-container & {
      .p-w-r {
        .pr-snippet .pr-snippet-stars-png,
        .pr-rating-stars {
          display: flex;
        }
      }
      .product-full__review-snippet .pr-rating-stars .pr-star-v4 {
        display: block;
      }
    }
  }
  .mpp-container &,
  .spp-cross-sells & {
    .product-brief__shades {
      margin: 0 0 24px;
      .product-brief-shades__header-wrapper,
      .product-brief-shades__header {
        @if $cr24_plp == true {
          margin-bottom: 7px;
        }
      }
    }
    .product-brief__sized {
      &-title {
        margin: 0;
      }
      &-buttons {
        min-height: auto;
        .sku-list {
          &__list {
            min-height: auto;
            margin: 0;
            padding: 0;
          }
          &__item {
            margin: 0;
          }
        }
      }
    }
    .product-brief__abstract-bottom {
      margin-bottom: 20px;
      @include breakpoint($medium-up) {
        margin-bottom: 24px;
      }
    }
  }
  &__vto,
  &__foundation-vto {
    display: inline-table;
    width: 50%;
    text-align: center;
    padding-#{$rdirection}: 1px;
    &-cta {
      @include cta-primary;
      width: 100%;
      padding: 20px 10px;
      .icon--camera {
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-#{$rdirection}: 5px;
        fill: $color-white;
      }
    }
  }
  &[data-rating]:not([data-rating='0.0']) {
    .product-brief__headline-review-link {
      display: inline-block;
    }
  }
  &__shades-overlay__options,
  &__sized-overlay__options {
    &#cboxOverlay {
      background: $color-gray-op40;
      border: none;
    }
  }
  .mpp-container--product-brief-short & {
    flex-direction: row;
    align-items: normal;
    &__image-container {
      @include swap_direction(margin, 0 15px 30px 0);
      width: 100px;
      .product-brief__image-wrapper {
        min-height: 100px;
        .slick-dots {
          top: 100%;
        }
      }
      .product-brief__image-badge {
        display: none;
        @include breakpoint($medium-up) {
          display: block;
        }
      }
    }
    &__product-info-wrapper {
      flex: 1;
    }
    &__abstract {
      flex: none;
    }
    &__bottom {
      .product-brief__add-to-bag {
        .product__button {
          @include cta-text;
          padding: 0;
          background: none;
          text-align: #{$ldirection};
          color: $color-gray-on-light;
          &:hover {
            background: none;
          }
          &.button--disabled {
            @include cta-text-gray;
          }
        }
      }
    }
    &__vto,
    &__foundation-vto {
      display: none;
    }
  }
}

.overlay-open {
  overflow: hidden;
}
